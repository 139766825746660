.title-row{
    /* height: 125px; */
    text-align: center;
    font-size: clamp(1.4rem, 2.7vw, 2.0rem );
    padding: 1.5625rem 0px;
    margin: .625rem 0px;
}

.title-row h1{
    padding-top: .9375rem;
    outline: thick double #592c22;
    /* background-color: #BF8F65; */
}


.map-row{
    padding: 1.5625rem;
    background-color: #2A4040;
    display:flex;
    justify-content:space-between;
}

.tet-container{
    flex: 1 1 50%;
    color: #DCBFA6;
    text-align: center;
    font-size:large;
    font-size: clamp(1.2em,3.3vw,1.5em);
    line-height: 2.0em;
    padding: 6.25rem 1.5625rem;
}

.tet-container h2, .tet-container p{
    margin: .9375rem;
}

.location-despo-cont{
    flex: 1 1 50%;
    /* outline: #BF8F65 ridge 4px; */
    min-height: 300px;

}
@media screen and (max-width: 860px){
    .map-row{
        flex-direction: column;
    }

    .tet-container{
        padding:1.5rem;
        line-height: 1.7em;
    }
    .tet-container h2, .tet-container p{
        margin: none;
    }
}

@media screen and (max-width: 520px){
    .tet-container{
        padding:1rem;
        line-height: 1.5em;
    }

    

}

.locations-grid{
    margin: 1.5625rem 0px;
    background-color: #592c22;
    /* background-color: #261610; */
    padding: 1.5625rem;
    display: grid;
    grid-gap: 1.5625rem;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 1160px){
    .locations-grid{
        grid-template-columns: 1fr;
        padding: 2rem 3rem;
    }
    
    



}

.location-container{
    outline: #592c22 ridge .25rem;
    background-color: #BF8F65;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    /* margin: 25px; */
}

.location-top-row{
    display: flex;
}
.location-top-row img{
    max-height: 13rem;
    max-width: 25rem;
}

.loc-title{
    text-decoration: underline;
}

.top-row-col{
    padding: 1.5625rem;
    flex: 1 1 50%;
}

.loc-top-row-content{
    text-align: center;
    line-height: 2.0em;
    font-size: clamp(1rem, 3.5vw, 1.3em);
}

.location-bot-row{
    border-top: .25rem ridge #592c22;
    display: flex;
    
}

.location-bot-row h3{
    flex: 1 1 33.3%;
    justify-content: space-between;
    text-align: center;
    padding: .375rem;
    font-size: clamp(1rem, 3.5vw, 1.3em);
}


a{
    color:#261610;
}

@media screen and (max-width: 720px){
    .location-top-row{
        flex-direction: column;
    }
    .location-top-row img{
        margin:auto;
    }

    .top-row-col{
        padding:1rem;
    }

    .locations-grid{
        padding: 1.25rem;
    }

}



