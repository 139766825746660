.form-container{
    width: 100%;
    display: flex;
}

.white-space{
    height:100%;
    width:40%;
}

@media screen and (max-width: 1220px){
    .white-space{
        width:15%;
    }
}