.cu-form-cont{
    margin: 1.5625rem;
    background-color: #BF8F65;
    padding: 1.5625rem;
    outline: #592c22 ridge .375rem;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
}

.em-header-row{
    margin-top: .9375rem;
}

.em-header-row h1{
    margin-bottom: .9375rem;
}

.em-header-row p{
    line-height: 1.2rem;
    font-size: 1.2rem;
}


.cu-name-row {
    display: flex;
    margin-top: 1.5625rem;
    gap:10%;
}

.cu-name-row div{
    width:50%;
}


.page-container{
    display: flex;
}

.cu-email-form{
    height:100%;
    /* padding: 25px; */

}
label{
    font-weight: 600;
}

.cu-email-form input[type=text], .cu-email-form input[type=email], .cu-email-form textarea {
    width: 100%;
    padding: .75rem;
    /* border-radius: 4px; */
    resize: vertical;
    max-height: 9.375rem ;
    margin-top: .375rem;
    margin-bottom: .75rem;

}

.cu-email-form input[type=submit]{
    color:aliceblue;
    /* color: #DCBFA6; */
    margin-top: .625rem;
    font-size: .9em;
    font-weight:600;
    outline: #592c22 ridge .1875rem;
    background-color:#592c22;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    padding: .75rem 1.25rem;
    cursor: pointer;
}

