* {
  box-sizing: border-box;
  margin: 0;
  padding: 0 ;
  font-family: 'Crimson Text', serif;
  font-weight: normal 400;

}
html, body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

body {
  color: #592c22;
  background-color: #BFB7B0;
  

}

h1{
  font-family: 'Josefin Sans', sans-serif;
  font-weight: light 300;
}

h2{
  font-family: 'Crimson Text', serif;
  font-weight: normal 400;
  
}

p{
  font-family: 'Crimson Text', serif;
  font-weight: normal 400;
}

h3{
  font-family: 'Josefin Sans', sans-serif;
  font-weight: Bold 700;
}

