
.title-row-abtus{
    text-align: center;
    font-size: clamp(1.4rem, 2.7vw, 2.0rem );
    padding: 1.5625rem 0px;
    margin: .625rem 0px;
    

}

.title-row-abtus h1{
    padding-top: .9375rem;
    outline: thick double #592c22;
    

}

.abtus-soc-equ-row{
    width: 100%;
    background: #261610;
    padding: 3.125rem 6.25rem;
    margin-bottom: 1.5625rem;
}

.abtus-soc-equ-container{
    padding: 3.125rem 2.5rem;
    color: #261610;
    height: 100% ;
    width: 100%;
    background-color: #DCBFA6;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    outline: #261610 ridge .375rem;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);

}

.abtus-soc-equ-container img{
    margin: auto;
    flex: 0 0 20%;
    width: clamp(200px, 50%, 500px);
    outline: #261610 ridge .25rem;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
}

.abtus-soc-equ-container h2{
    text-align: center;
    /* padding: 15px; */
    text-decoration: double underline;
    font-size: clamp(1.7rem, 2.8vw, 2.5rem);
    margin-bottom: 3.125rem;
}

.abtus-soc-equ-content{
    display: flex;
    /* text-align: center; */
    gap: 3.125rem;
    padding: 1.5625rem;
}

.abtus-soc-equ-content-paragraph{
    flex: 1 1 40%;

}

.abtus-soc-equ-content p {
    font-size: clamp(1rem, 2.4vw, 1.25rem);
    line-height: 1.5em;
    text-align: center;
    
}

@media screen and (max-width: 1160px){
    .abtus-soc-equ-content{
        display: flex;
        flex-direction: column;
    }

    .abtus-soc-equ-row{
        padding: 3.125rem;
    }

    .abtus-soc-equ-container h2{
        margin-bottom:1.5rem;
    }
    .abtus-soc-equ-content{
        padding:1rem;
        gap: 1.5rem;
    }
}

@media screen and (max-width: 520px){
    .abtus-soc-equ-row{
        padding:1.5rem;
    }
    .abtus-soc-equ-container{
        padding:2rem 1rem;
    }
}

.abtus-whowhat-row{
    width: 100%;
    padding: 3.125rem;
    background: #2A4040;
    margin-bottom: 1.5625rem;
}

.abtus-whowhat-cont{
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-between;
    
}

.abtus-whowhat-cont-left, .abtus-whowhat-cont-right{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 1rem;
    gap: 1rem;
    flex: 1 1 40%;
    background-color: #592c22;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    outline:#261610 ridge 6px;
}

.abtus-whowhat-cont-center{
    margin: auto;
    flex: 1 1 6%;
}

.abtus-whowhat-cont-center img{
    outline:#261610 ridge .25rem;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    margin-bottom: .3125rem;

}

.abtus-whowhat-cont-left h2, .abtus-whowhat-cont-right h2{
    padding: .5rem;
    text-align: center;
    font-size: clamp(1.2rem, 3.2vw, 1.5rem);
    border:#261610 ridge .25rem ;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    margin: auto;
    background: #DCBFA6;

}
.abtus-whowhat-cont-left p, .abtus-whowhat-cont-right p{
    text-align: center;
    border:#261610 ridge .25rem ;
    font-size: clamp(1rem, 3.0vw,  1.2rem);
    line-height: 1.2em;
    background:#DCBFA6;
}

.abtus-whowhat-cont-left p{
    padding: 2.1875rem .9375rem;

}

.abtus-whowhat-cont-right p{
    padding: 1.5625rem .9375rem;

}
@media screen and (max-width: 1120px){
    .abtus-whowhat-cont-center img{
        margin:.5rem;
    }
    .abtus-whowhat-cont{
        display:flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 600px){
    .abtus-whowhat-cont-center{
        text-align: center;
    }

    .abtus-whowhat-cont-center img{
        margin:.5rem;
        max-width: 25%;
    }
    .abtus-whowhat-cont-left p, .abtus-whowhat-cont-right p{
        line-height: 1.15rem;
        padding:1rem;
    }

}

.abtus-ext-methods-row{
    width:100%;
    background-color:#BF8F65;
    padding: 2.1875rem 9.375rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1.5625rem;
    margin-bottom: 1.5625rem;
}
.abtus-ext-methods-center, .abtus-ext-methods-bottom{
    flex: 1 1 44%;
    display: flex;
    /* max-height: 40rem; */
    background-color:#DCBFA6;
    border:#261610 ridge .25rem ;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    justify-content: space-evenly;
    padding: 1.5625rem;
}
.abtus-ext-methods-center h3, .abtus-ext-methods-bottom h3{
    font-size: clamp(1.1rem, 3.5vw, 1.35rem);
    line-height: 1.3em;
    flex: 0 0 20%;
    margin: .5rem;

}

.abtus-ext-methods-center p, .abtus-ext-methods-bottom p{
    font-size: clamp(1rem, 3.3vw,  1.3rem);
    /* font-size: 3.5vw; */
    line-height: 1.3em;
    flex: 0 0 70%;
    
}


.abtus-ext-methods-top{
    flex: 1 1 12%;
    padding: .625rem;
    text-align: center;
    font-size: clamp(1.2rem, 3.6vw, 1.4rem);
    background-color:#DCBFA6;
    border:#261610 ridge .25rem ;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);

}

@media screen and (max-width: 720px){
    .abtus-ext-methods-row{
        padding:2rem 2.5rem;
    }


}

.abtus-founder-row{
    width: 100%;
    padding:2rem 5rem;
    background-color: #261610;
    
}

.abtus-founder-container{
    background-color: #DCBFA6;
    display: flex;
    justify-content: space-evenly;
    outline: #261610 ridge .375rem;
    padding: 1rem;
    
}

.abtus-founder-pic{
    flex: 0 0 25%;
    margin :1rem 0;
    max-height: 600px;
    border: #261610 ridge .25rem;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);

}

.abtus-founder-content{
    color: #261610;
    flex: 0 0 45%;
    font-size:clamp(.9rem,3vw,1.3rem);
    /* font-size:1rem; */
    /* font-size:1.3rem; */
    line-height: 1.5rem;
    /* padding: 1.5rem; */
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding:1rem;


}

.abtus-founder-content h2{
    text-decoration: underline;
    margin-bottom: 1.5rem;
}

@media screen and (max-width: 1120px){
    .abtus-founder-container{
        flex-direction: column;
        gap:1rem;
    }

    .abtus-founder-pic{
        max-height: 400px;
        max-width: 75%;
        margin: auto;
    }

}

@media screen and (max-width: 720px){
    .abtus-founder-row{
        padding:2rem;
    }
    .abtus-founder-content{
        line-height: 1.3rem;
    }

    .abtus-founder-pic{
        max-height: 300px;
    }

}

