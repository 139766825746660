.footer{
    background-color:#592C22;
    color:white;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 2.5rem 0;
}
.footer-container{
    /* max-width: 1000px; */
    padding: 0 10rem;
    display: flex;
    /* flex-wrap:wrap; */
    justify-content:center;
    /* gap:5%; */
}
.footer-left,.footer-center-left, .footer-center-right,.footer-right{
    flex: 1 1 20%;
    text-align: center;

    padding: .625rem 1.25rem;
}

/* .footer-left{
    background-color: aliceblue;
} */

/* .footer-center-left{
    background-color: antiquewhite;
} */

.footer-center-left p {
    padding-top: .5rem;
    font-size: .8125rem;

}

/* .footer-center-right{
    background-color: beige;
} */
.footer-center-right ul{
    list-style-type: none;
    padding-top: .5rem;
}
.footer-center-right li{
    margin-bottom:.25rem;
    font-size: .8125rem;
    font-weight: 500;
}

/* .footer-right{
    background-color: bisque;
} */

.footer-pic{
    /* border-radius: 8%; */
    box-shadow: 29px 6px 48px 11px rgba(0,0,0,0.49);
}

.footer-link{
    color:white;
}

@media screen and (max-width: 960px){
    .footer-container{
        display: grid;
    }
}