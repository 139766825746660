.trow-SS-row {
    /* width: 100%; */
    padding: 3.125rem;
}

.trow-SS-cont{
    outline: #572D2D ridge .375rem;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    background-color: #DCBFA6;
    
}

.lhome-link{
    color: aliceblue;
    outline: #BF8F65 ridge .125rem;
    background-color: #592c22;
    margin: .9375rem;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    padding: .625rem .125rem;
    font-weight:600;
}
.arrow-back-btn{
    margin-bottom: -.4375rem;
    margin-right: .1875rem;
}
.trow-SS-cont-content{
    padding: 1.5625rem 1.5625rem 3.125rem;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    flex-wrap: wrap;

}

.trow-SS-left, .trow-SS-right{
    flex: 1 1 45%;
    padding: .9375rem;
}



.trow-SS-left{
    text-align: center;
}

.trow-SS-right h1{
    margin-bottom: 1.25rem;
    font-size:clamp(1.6rem, 5.5vw, 2.5rem);
    text-decoration: underline ;
}

.trow-SS-right h4{
    font-size:clamp(1rem, 3vw, 1.2rem);
    margin-bottom: .1875rem;
}

.trow-SS-right p{
    font-size:clamp(.9rem,3vw,1.1rem);
    line-height: 1.3em;
}

.trow-SS-product-pic{
    height: 25rem;
    width: 25rem;
    outline: #592c22 ridge .375rem;
    box-shadow: 10px 10px 202px -27px rgba(0,0,0,0.75);

}

.trow-SS-product-pic:hover{
    cursor: pointer;
}
.sm-SS-pic-row{
    padding: .9375rem 0px;
    display: flex;
    justify-content: center;
    gap: 1.5625rem;

}

.sm-SS-pic-row img{
    height: 3.125rem;
    width: 3.125rem;
    outline: #592c22 ridge .125rem;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
}
.sm-SS-pic-row img:hover{
    cursor: pointer;
}

.trow-SS-desc-row{
    margin: .9375rem 0;
    
}

@media screen and (max-width: 600px){
    .lhome-link{
        padding:.5rem;
    }
    .trow-SS-product-pic{
        width:80%;
        height:80%;
    }

    .trow-SS-row{
        padding:1.5rem;
    }

    .trow-SS-cont-content{
        padding:1.5rem;
    }

    .trow-SS-right p{
        line-height:1.1rem;
    }

    .trow-SS-cont-content{
        display:flex;
        flex-direction: column;
    }

}


.disclaimer{
    font-size: .7em;
    line-height: 1.2em;
}

.SS-extras-row{
    border-top: .375rem solid #572D2D ;
    margin: 0px 3.125rem;
    padding: 3.125rem;
    gap:1.5625rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.SS-extras-row-left, .SS-extras-row-right{
    background-color:#BF8F65;
    padding: 1.25rem;
    flex: 1 1 45%;
    outline: #572D2D ridge .25rem;

}

.SS-extras-row h3{
    margin-bottom: .9375rem;
    font-size: 1.1em;
    text-align: center;
    text-decoration: underline;
}

.SS-extras-row-left p{
    margin-bottom: .625rem;

}

.SS-extras-row-right-products{
    margin-top: 1.5625rem;
    display:flex;
    gap:1.5625rem;
    justify-content: space-evenly;
}

.SS-extras-row-right-prod{
    flex: 1 1 40%;
    padding: .625rem;
    text-align: center;
    background-color: #DCBFA6;
    outline: #572D2D ridge .25rem;
    display:flex;
    gap:.625rem;
    flex-direction: column;
    justify-content: space-between;
}

.SS-extras-row-right-prod img{
    margin:auto;
    height: 6.25rem;
    width: 6.25rem;
    outline: #572D2D ridge .1875rem;
    box-shadow: 10px 10px 202px -27px rgba(0,0,0,0.75);
    
}

.SS-extras-row-right-prod button{
    color: aliceblue;
    outline: #BF8F65 ridge .0625rem;
    background-color: #592c22;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    padding: .3125rem 3.125rem;
    font-weight:600;
    margin-top: .625rem;
}

.SS-extras-row-right-prod-cont{
    padding: .625rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 600px){
    .SS-extras-row{
        padding:1.5rem .5rem;
        margin: 0px 1.5rem;
    }

    .SS-extras-row-right-prod img{
        max-width: 65%;
        max-height: 35%;
    }

    .SS-extras-row-left p{
        font-size: .9rem;
    }

    .SS-extras-row-right-prod button{
        padding:.5rem 1.5rem;
    }

    .SS-extras-row-right-prod-cont{
        padding:.5rem;
        font-size: .9rem;
    }

    .SS-extras-row-right-prod{
        gap:.325rem;
    }

    .SS-extras-row-right-prod button{
        margin-top: .325rem;
    }
}