.dropdown-menu {
    background: red;
    width: 200px;
    position: absolute;
    top: 80px;
    list-style: none;
    text-align: start;
    z-index: 9999;
}

.dropdown-menu li {
    background: #BF8F65;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background: #261610;
}

.dropdown-menu.clicked {
    display: none;
}

.dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
}

@media screen and (max-width: 960px) {
    .fa-caret-down {
        display: none;
    }
}