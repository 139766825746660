

.overlay{
    background-color: rgba(0,0,0,0.8);
    top: -10%;
    width: 100%;
    height:110%;
    position:fixed;
}

.modalContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 90%;
    width: 90%;
    position: fixed;
    left:5%;
    top: 5%;
    background-image: url("./images/Gray_MD_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.75);
    
}

.modalContent{
    padding:1.5rem 1rem;
    text-align: center;
    margin-right: 50%;
    margin-top:7.5%;
    line-height: 2.0rem;
    font-size: 1.1rem;
    
}

.modalContent img{
    outline: #BF8F65 ridge 4px;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    max-height:35%;
    max-width:65%;
}

.modalbuttons{
    display: flex;
    justify-content: center;
}
.modalbuttons button{
    color:#DCBFA6;
    outline: #BF8F65 ridge .2rem;
    background-color: #592c22;
    margin: 1.25rem;
    font-size: 1.15rem;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    /* padding: .9375rem 2rem; */
    padding: .8rem 1.75rem;
    font-weight:600;

}

.modalContent p{
    font-size: 1.3rem;
}


.modalContent h1, .modalContent p{
    margin: 1.25rem 0px;
    
    

}

.noPopup{
    color: red;
    font-weight: 700;

}

@media screen and (max-width: 1200px){
    .modalContainer{
        background-image: none;
        background-color: #DCBFA6;
    }

    .modalContent{
        margin: auto;
    }

    .modalContent h1, .modalContent p, .modalContent img, .noPopup{
        margin-bottom: 2.5rem;
    }
    
}