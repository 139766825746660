

.welcome-container{
    margin: 2rem 5rem;
    max-width:90%;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    display: flex;
    outline: ridge .45rem;

}
@media screen and (max-width: 1160px) {
    .welcome-container{
        flex-direction: column;
        justify-content: space-between;
        height: 1000px;
        max-width: 80%;
        margin: 2rem 10%;
    }
    .welcome-container-left{
        flex-direction: row;
    }
    .wel-cont-l-top{
        flex: 1 1 50%;
    }

    .wel-cont-l-bottom{
        flex: 1 1 50%;

    }

}



.welcome-container-left{
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    /* max-height: 600px; */

}

.wel-cont-l-top{
    background-color:#DCBFA6;
    text-align: center;
    flex: 1 1 50%;
    /* font-size:1.5rem; */
    font-size: clamp(1.1rem, 2.8vw, 1.5rem);
    padding: .625rem 1.25rem;

}

.wel-cont-l-top img{
    width: clamp(15rem, 52vw, 23.125rem);
    height: auto;
    margin: 1.25rem 0px;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);

}



.wel-cont-l-bottom{
    padding-top: 2.5rem;
    text-align: center;
    font-size: clamp(1.1rem, 2.5vw, 1.5rem);
    color:#BF8F65;
    background-color: #592c22;
    flex: 1 1 50%;
    
}

.wel-cont-l-bottom p{
    padding: 1.25rem;
}

.buttn-row button{
    color:#592c22;
    outline: #BF8F65 ridge .125rem;
    background-color: #DCBFA6;
    margin: 1.25rem;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    padding: .9375rem 2rem;
    font-weight:600;
    /* font-size: 1em; */
}

@media screen and (max-width: 520px){
    .wel-cont-l-top{
        padding:.5rem;
    }

    .wel-cont-l-bottom{
        padding:.5rem;
    }

    .buttn-row button{
        padding:1rem;
    }

}



.carousel{
    flex: 1 1 50%;
    /* overflow: hidden; */
    display:flex;

}

.carousel_wrapper{
    position: relative;
    width: 100%;
    height: 100%;
}

.carousel_card{
    display: flex;
    flex: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* border-radius: 20px; */
    -webkit-box-shadow: 0px 0px 11px 3px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 11px 3px rgba(0,0,0,0.15);
    opacity: 0;
    pointer-events: none;
    transform: scale(.8);
    transition: 0.9s ease-in-out;
}

.carousel_card-active{
    opacity: 1 ;
    transform: scale(1);
    pointer-events:visible;
}


.card_pic{
    width:100%;
    object-fit: cover;
}

.card_overlay{
    position:absolute;
    width:100%;
    height:100% ;
    background-color:rgba(0,0,0,.4);
    display: flex;
    padding: 2.5rem 1.875rem;
    align-items:flex-end;
}

.card_title {
    color:white;
    font-size: 1.875rem;
}
.carousel_arrow_left,.carousel_arrow_right {
    position: absolute; 
    z-index: 0;
    font-size: 1.875rem;
    top: 50%;
    transform: translate(0, 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    padding: .5rem .25rem;


}

.carousel_arrow_left{
    left: 1rem;

}
.carousel_arrow_right{
    right: 1rem;
}


.carousel_pag{
    position: absolute;
    bottom: .3125rem;
    left: 50%;
    transform: translate(-50%, 0);
}
.pag_dot{
    height: .625rem;
    width: .625rem;
    background-color: #f5f5f5;
    border-radius: 50%;
    display: inline-block;
    margin-left: .625rem;
    cursor: pointer;

}

.pag_dot:hover{
    transform: scale(1.2);

}

.pag_dot-active{
    background-color: gray;
}

/* 

END OF CAROUSEL

*/

@media screen and (max-width: 1240px){
    .whatdo-right{
        display: none;
    }

    .whatdo-left{
        margin: auto;
        /* padding: 3rem; */
        

    }

    .whatdo-center{
        margin: auto;
        padding: 3rem;
        
    }
}



.whatdowedo-row{
    padding: 3.125rem 1rem;
    background-color: #BF8F65;
    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-evenly;
}



.whatdowedo-row img{
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    outline: #592c22 ridge .25rem;
    width: clamp(300px, 25vw, 350px);
    max-height: 75vh;
}

.whatdo-center{
    max-width:40%;
    padding: 1rem 0rem;
    /* margin: 1rem; */
    
}

.whatdo-center h2{
    text-decoration: double underline;
    text-align: center;
    font-size: clamp(1.7rem, 4.5vw, 2.3rem);
}

.whatdo-center-desc{
    padding: 1.5625rem 1rem;
    text-align: center;
    margin: 3.125rem 0px 1.5625rem;
    line-height: 1.4em;
    font-size: clamp(1rem, 3vw, 1.3rem);
    /* font-size: 3vw; */
    background:#DCBFA6;
    outline: #592c22 ridge .25rem;

}
button:hover {
    cursor: pointer;
}

@media screen and (max-width: 840px){
    .whatdowedo-row{
        display: flex;
        flex-direction: column-reverse;
    }

    .whatdo-center{
        max-width:70%;
    }

}

@media screen and (max-width: 520px){
    .whatdowedo-row{
        padding:1rem 0px 2rem;

    }

    .whatdo-center-desc{
        margin: 1.5rem 0px;
    }

}




.core-values-row{
    color: #DCBFA6;
    background-color: #261610;
    margin-top: 1.5625rem;
    padding: 3.125rem 3.75rem;
    width: 100%;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    /* display: flex;
    gap: 3.125rem;
    justify-content: space-between; */
}


@media screen and (max-width: 840px){
    .core-values-row{
        grid-template-columns:1fr
    }
}

.cv-col{
    padding: 1.5625rem 1.875rem;
    text-align: center;
    outline: #DCBFA6 ridge .25rem;
    /* flex: 1 1 20%; */
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

}

.core-values-title{
    /* padding:1.5rem; */
    font-size: 1.75rem;
}

.cv-col h2{
    text-decoration: underline;

}

.cv-col p{
    font-size: clamp(.9rem, 2.5vw, 1.25rem );
    /* font-size: 2.5vw ; */
    line-height: 1.4em;
    /* margin-top: 1.25rem; */
    padding: 1rem;
}

.location-home-row{
    width: 100%;
    padding: 1.5625rem 9.375rem;
}

.location-home-cont{
    padding: 3.175rem;
    display:flex;
    gap:1.5625rem;
    width: 100%;
    background: #2A4040;
    justify-content: space-evenly;
    outline: #592c22 ridge .375rem;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
}

.location-home-cont-left{
    text-align: center;
    flex: 1 1 40%;

}

.location-home-cont-right{
    padding: 1.5625rem 2rem;
    /* width:500px; */
    flex: 1 1 40%;
    /* width: 30em; */
    background: #DCBFA6;
    display: flex;
    flex-direction: column;
    gap:2rem;
    justify-content:space-evenly;
    outline: #592c22 ridge .25rem;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);

}

.location-home-cont-right h2{
    text-align: center;
    text-decoration: double underline;
    font-size: clamp(1.3rem, 2.5vw, 2.0rem);
    /* line-height: 2.0em; */
}
.location-home-cont-right p{
    text-align: center;
    font-size: clamp(1rem, 2.5vw, 1.4rem );
    /* font-size:1.5em; */
    line-height: 1.4em;

}

.location-home-cont-right button{
    color:aliceblue;
    outline: #BF8F65 ridge 2px;
    background-color: #592c22;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    padding: .9375rem 2rem;
    font-weight:600;
}
.location-home-cont-right div{
    text-align: center;
}

@media screen and (max-width: 1220px){
    .location-home-cont{
        display:flex;
        flex-direction:column-reverse;
        padding: 2rem;
        /* min-width: 380px; */
        

    }

    .location-home-cont-left{
        min-height: 300px;
    }

    .location-home-row{
        padding: 1.5625rem 5rem;
    }


}

@media screen and (max-width: 640px){
    .location-home-row{
        padding:1.5625rem;

    }

}

/* .homepage-contact-row{
    background: #BF8F65;
    padding: 50px;
    height: 600px;
    width: 100%;
}

.hpc-title{
    
    text-align: center;

}

.hpc-title h2{
    background-color:#DCBFA6;
    padding: 10px;
    font-size: 1.7em;
    outline: thick double #592c22;

}

.home-email-form{
    font-size: medium;
    background-color: #DCBFA6;
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 15px;
    margin: 50px 0px;
    height: 400px;
    width: 100%;
}

.home-email-form-item{
    padding-left: 15px;
    display: flex;
    justify-content: space-between;
}

.home-email-form-item label{
    padding-top: 15px;
    font-size: 1.1em;
}

.home-email-form input[type=text], .home-email-form input[type=email], .home-email-form textarea {
    width: 75%;
    padding: 12px;
    resize: vertical;
    max-height: 150px ;
    margin-top: 6px;
    margin-bottom: 12px;

}

.home-email-form input[type=submit]{
    color:#592c22;
    background-color:#BF8F65 ;
    padding: 15px 32px;
    outline: #592c22 ridge 2px;
    border: none;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    cursor: pointer;
    font-size: 1em;
    font-weight:600;
    margin-left: 450px;
} */




