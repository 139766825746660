.title-row-part-br{
    /* height: 125px; */
    text-align: center;
    font-size: clamp(1.4rem, 2.7vw, 2.0rem );
    /* font-size: 2.7vw; */
    padding: 1.5625rem 0px;
    margin: .625rem 0px;
    /* background-color: #BF8F65; */
}

.title-row-part-br h1{
    padding-top: .9375rem;
    outline: thick double #592c22;
    /* background-color: #BF8F65; */
}

.brand-row{
    padding: 1.5625rem;
    display: flex;
    background-color: grey;
    justify-content:space-evenly;
    outline: #592c22 ridge .375rem;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
}

.brands-container{
    padding: 1.5625rem 3.125rem 3.125rem;
    display: flex;
    flex-direction: column;
    gap: 3.125rem;
}

.brand-logo{
    padding: 1.5625rem;
    flex: 0 0 20%;
    text-align: center;

}
.brand-logo img{
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
}

.brand-description{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 70%;
    padding: 1.5rem 5rem;
    gap:1rem;
}

.br-desc-title{
    text-align: center;
    font-size: clamp(1.5rem, 2.7vw, 2.0rem );
    text-decoration: underline;
}

.br-desc-words{
    text-align: center;
    font-size: clamp(1.2rem, 2.7vw, 1.6rem );
    line-height: 1.5em;
}

@media screen and (max-width: 960px){
    .brand-row{
        flex-direction: column;
        padding:0px;
    }

    .brand-logo{
        max-width: 450px;
        margin: auto;
    }

    .brands-HASH{
        flex-direction: column-reverse ;
    }

    .brands-container{
        padding: 1.5625rem 1.5rem 3.125rem;

    }

    .brand-description{
        padding:1.5rem 2.5rem;
    }

}

.br-desc-btn-row{
    display: flex;
    justify-content: center;
    
}



.br-desc-btn-row button{
    color:#592c22;
    /* outline: #BF8F65 ridge 2px; */
    margin: 1.25rem;
    border: none;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    padding: .9375rem 2rem;
    font-weight:600;
    font-size: clamp(.7rem, 2.3vw, 1.0rem );

}

@media screen and (max-width: 520px){
    .brand-description{
        padding:1rem;
    }

    .brand-logo{
        padding:1rem;
    }

    .br-desc-btn-row button{
        padding:1rem;
        margin:1rem;
    }
    

}

.brands-lux{
    background-color: #DCBFA6;
    
}

.brands-lux button{
    background-color: #BF8F65;
    outline: #592c22 ridge .1875rem;
    
}

.brands-HASH{
    background-color: #BF8F65;
    

}

.brands-HASH button{
    
    background-color: #DCBFA6;
    outline: #BF8F65 ridge .1875rem;
}

.brands-Ryder{
    background-color: #BF8F65;
}

.brands-Ryder button{
    background-color: #DCBFA6;
    outline: #BF8F65 ridge .1875rem;
}


