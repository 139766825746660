

.top-row {
    background-image: url("../images/LUX_icon2.png"), url("../images/LUX_icon1.png") ;
    background-position: left top, right top ;
    background-repeat: no-repeat, no-repeat;
    height: 46.875rem;
    padding:7.8125rem 0px;
}

.top-row-container{    
    height: 31.25rem;
    width: 100%;
    background-color: #BFB7B0;
    opacity: 0.4;
    outline: .125rem ridge white;
    text-align: center;
    padding-top: 6.25rem;
    

}

.lux-logo{
    outline: .3125rem ridge black;
}

.about-container{
    /* padding: 3.125rem; */
    /* margin-bottom:1.5625rem; */
    background-color: #DCBFA6;
    /* border-bottom: 5px solid #592c22; */

}
.ar-title{
    text-align: center;
    font-size: clamp(1.6rem, 2.85vw, 2.5rem );
    padding: 3.125rem 0px;
}

.ar-title h1{
    padding-top: .9375rem;
    outline: thick double #592c22;
}

.about-row{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 3.125rem 0px 4.6875rem;

}

.arow-col-left, .arow-col-right{
    flex: 1 1 40%;
    
}

.arow-col-left{
    text-align: center;
}

.arow-col-left img {
    outline: #905d3c ridge .5rem;

}

.arow-col-right {
    text-align: center;
    padding: 1.5625rem 3.125rem;
    
}
.arow-col-right p{
    font-size: clamp(1.2rem, 2.7vw, 1.5rem );
    line-height: clamp( 1.4rem, 5.3vh, 2.7rem);
    font-weight: 500;
}

@media screen and (max-width: 960px){
    .about-container{
        width:100%;
        /* margin:5%;
        outline:#592c22 ridge .5rem;
        box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75); */
    }

    .about-row{
        flex-direction: column;
        gap:2rem;
        padding: 2rem 0;
        /* justify-content: space-evenly; */
    }


}

@media screen and (max-width: 520px){
    .ar-title{
        padding:1.5rem 0;
    }
    .about-row{
        padding:1rem 0;
    }
    .arow-col-right{
        padding:1.5rem;

    }
}

.products-row-container{
    background-color: #DCBFA6;
}


.products-row-title{
    text-align: center;
    font-size: clamp(1.4rem, 5vw, 2.0rem);
    /* font-size: 5vw; */
    /* font-size: 2.0rem; */
    padding: 1.5625rem 0px 3.125rem;
    
}

.products-row-title h1{
    background: #BF8F65;
    text-decoration-line: underline;
    text-decoration-thickness: .1875rem;
    text-decoration-style: double;
    padding: .625rem 0px;
}


.products-row {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
    justify-content: space-evenly;
    padding: 0px 3.125rem 3.125rem 3.125rem;
    width:100%;

}

.product-one, .product-two, .product-three{
    /* flex: 1 1 30%; */
    /* width: 30%; */
    max-width:40rem;
    background-color: #BF8F65;
    padding: 1.25rem;
    outline: #592c22 ridge .5rem;
    box-shadow: 10px 10px 50px -26px rgba(0,0,0,0.75);
    display: flex;
    
    flex-direction: column;
    justify-content: space-between;
}

.pic-container{
    display:flex ;
    justify-content: center;
}

.pic-container img{
    outline: #592c22 ridge .25rem;
    max-width: 80%;
    
}


.p1-desc, .p2-desc, .p3-desc{
    padding: .9375rem .9375rem;
    text-align: center;

}
.p1-desc p, .p2-desc p, .p3-desc p{
    margin-top: .5rem;
    text-align: center;
    font-size: 1.2rem;
}

.p1-desc h2, .p2-desc h2, .p3-desc h2{
    margin-top: .5rem;
    text-align: center;
}


.btn-row{
    margin-top: 1.25rem;
    text-align: center;
}

.btn-row button{
    outline: #BF8F65 ridge .125rem;
    box-shadow: 10px 10px 100px -26px rgba(0,0,0,0.75);
    padding: .9375rem 2rem;
    cursor: pointer;
    font-weight:600;

}

.p3-btn{
    color:white;
    background-color: #003A40;
}

.p1-btn{
    color:white;
    background-color: #572D2D;

}

.p2-btn{
    color:white;
    background-color: #864A33;

}

@media screen and (max-width: 520px){
    .btn-row{
        margin-top:.5rem;
    }
    .btn-row button{
        padding:1rem;
    }

    .product-one, .product-two, .product-three{
        padding:.75rem;
    }

    .products-row-title{
        padding:2rem 0px;
    }

    .p1-desc, .p2-desc, .p3-desc{
        padding:.75rem;
    }

}

/* @media screen and (max-width: 1320px){
    .products-row{
        display: grid;
        grid-template-columns: 45% 45%;
        grid-template-rows: auto auto;

    }

} */












